<template>
  <div class="menu">
    <div
      :class="[
        'menu__item',
        {
          nested: menuItem.list && menuItem.list.length,
          active: selectedMenuItem === menuItem.label,
          'menu-opened': menuOpened,
        },
      ]"
      v-for="(menuItem, menuItemIdx) in menuList"
      :key="menuItemIdx"
    >
      <div
        class="menu__item-wrapper"
        @click="
          selectMenuItem(
            menuItem.label ? menuItem.label : menuItem.title,
            menuItemIdx
          )
        "
      >
        <router-link
          class="label-wrapper"
          :to="menuItem.link"
          v-if="menuItem.link"
        >
          <font-awesome-icon :icon="menuItem.icon" v-if="menuItem.icon" />
          <div class="label">{{ menuItem.label }}</div>
        </router-link>

        <a class="label-wrapper" v-else-if="menuItem.url" :href="menuItem.url">
          <img :src="menuItem.image" v-if="menuItem.image" />
          <div class="label">{{ menuItem.title }}</div>
        </a>

        <router-link
          class="label-wrapper"
          v-else
          :to="menuItem.link ? menuItem.link : menuItem.list[0].link"
        >
          <font-awesome-icon :icon="menuItem.icon" v-if="menuItem.icon" />
          <div class="label">{{ menuItem.label }}</div>
        </router-link>

        <FormulateInput
          class="dropdown"
          type="select"
          v-if="menuItem.dropdown && menuItem.dropdown.status"
          :value="menuItem.dropdown.value"
          :options="menuItem.dropdown.list"
          @input="menuItem.dropdown.action"
        />
      </div>

      <SubLinksList
        v-if="menuItem.list && menuItem.list.length"
        :class="['subitem', { visibile: selectedMenuItem === menuItem.label }]"
        :menu-list="menuItem.list"
        :parent-item="{ ...menuItem, index: menuItemIdx }"
      />

      <SubLinksList
        v-if="menuItem.list && menuItem.list.length"
        class="subitem__on-hover"
        :menu-list="menuItem.list"
        :parent-item="{ ...menuItem, index: menuItemIdx }"
      />
    </div>
  </div>
</template>

<script>
import SubLinksList from "@/components/Menu/SubLinksList";

export default {
  name: "LinksList",
  components: {
    SubLinksList,
  },
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
    menuOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedMenuItem: "",
    };
  },
  methods: {
    selectMenuItem(label) {
      this.selectedMenuItem = label;
    },
    checkActiveMenuItem() {
      let selected = "";
      this.menuList.forEach((item) => {
        if (item.relation && item.relation.includes(this.$route.meta.relation))
          selected = item.label;
      });
      this.selectedMenuItem = selected;
    },
    checkForActiveMenu() {
      this.menuList.forEach((menuItem) => {
        if (
          menuItem.relation &&
          menuItem.relation.length &&
          menuItem.relation.includes(this.$route.meta.relation)
        )
          this.selectMenuItem(menuItem.label);
      });
    },
  },
  mounted() {
    this.checkActiveMenuItem();
  },
  watch: {
    "$route.name"() {
      this.checkActiveMenuItem();
    },
    menuList() {
      this.checkForActiveMenu();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.menu {
  // font-family: "Oswald", sans-serif;
  margin: 0 -15px 15px;
  padding: 15px 0;
  background: $background_3;

  @media (min-width: 1200px) {
    margin: 0 -30px 30px;
    padding: 30px 0;
  }

  @media (min-width: 1600px) {
    margin: 0 -50px 25px;
    padding: 25px 0;
  }

  &__item {
    position: relative;
    color: #292929;
    font-weight: 500;
    font-size: 0.875rem;
    padding: 0 15px;
    cursor: pointer;

    &-wrapper {
      overflow: hidden;
      display: flex;
      justify-content: space-between;

      .dropdown {
        margin-left: 15px;
        ::v-deep &.formulate-input {
          .formulate-input-element {
            margin-bottom: 0;

            &:before {
              right: 7px;
            }
            select {
              padding: 3px 20px 3px 3px;

              &:hover,
              &:focus {
                border-color: $purple;
              }
            }
          }
        }
      }
    }

    .subitem__on-hover {
      position: absolute;
      left: 0;
      top: -15px;
      display: none;

      &.menu {
        margin: 0;
      }
    }

    &:not(.active) {
      &:hover {
        .subitem__on-hover {
          @media (min-width: 768px) {
            display: block;
            left: 100%;
          }
        }
      }
    }
    &:not(.menu-opened) {
      .menu__item-wrapper {
        @media (max-width: 1200px) {
          justify-content: center;
        }
      }
      svg {
        @media (max-width: 1200px) {
          margin-right: 0;
        }
      }
      img {
        @media (max-width: 1200px) {
          margin-right: 0 !important;
        }
      }
      &:hover {
        .subitem__on-hover {
          @media (min-width: 768px) and (max-width: 1200px) {
            display: block;
            left: 100%;
          }
        }
      }
    }

    @media (min-width: 1200px) {
      padding: 0 30px;
    }

    @media (min-width: 1600px) {
      padding: 0 50px;
    }

    &:not(:last-child) {
      margin-bottom: 7.5px;

      @media (min-width: 1200px) {
        margin-bottom: 12.5px;
      }

      @media (min-width: 1600px) {
        margin-bottom: 20px;
      }
    }

    &:hover,
    &.active {
      color: $purple;
    }

    &.active.nested {
      margin-bottom: 5px;
    }

    svg {
      margin-right: 10px;
    }

    .label-wrapper {
      display: flex;
      align-items: center;
      color: inherit;
      text-decoration: none;
      img {
        height: 1em;
        margin-right: 10px;
      }
    }

    @media (min-width: 1600px) {
      font-size: 1rem;
    }
  }
}

.module-editor {
  .menu {
    @media (min-width: 1200px) {
      margin: 0 -15px 30px;
      padding: 30px 0;
    }

    @media (min-width: 1500px) {
      margin: 0 -30px 30px;
      padding: 30px 0;
    }

    @media (min-width: 1600px) {
      // margin: 0 -30px 30px;
      margin: 0 -50px 25px;
      padding: 25px 0;
    }

    &__item {
      @media (min-width: 1200px) {
        padding: 0 15px;
      }

      &:not(.menu-opened) {
        .menu__item-wrapper {
          @media (max-width: 1500px) {
            justify-content: center;
          }
        }

        svg {
          @media (max-width: 1500px) {
            margin-right: 0;
          }
        }

        img {
          @media (max-width: 1500px) {
            margin-right: 0;
          }
        }

        &:hover {
          .subitem__on-hover {
            @media (min-width: 768px) and (max-width: 1500px) {
              display: block;
              left: 100%;
            }
          }
        }
      }

      @media (min-width: 1200px) {
        padding: 0 15px;
      }

      @media (min-width: 1500px) {
        padding: 0 30px;
      }

      @media (min-width: 1600px) {
        // padding: 0 30px;
        padding: 0 50px;
      }

      &.active.nested {
        margin-bottom: 5px;
      }

      &:not(:last-child) {
        margin-bottom: 7.5px;

        @media (min-width: 1200px) {
          margin-bottom: 12.5px;
        }

        @media (min-width: 1600px) {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu"},_vm._l((_vm.menuList),function(menuItem,menuItemIdx){return _c('div',{key:menuItemIdx,class:[
      'menu__item',
      {
        nested: menuItem.list && menuItem.list.length,
        active: _vm.selectedMenuItem === menuItem.label,
        'menu-opened': _vm.menuOpened,
      } ]},[_c('div',{staticClass:"menu__item-wrapper",on:{"click":function($event){return _vm.selectMenuItem(
          menuItem.label ? menuItem.label : menuItem.title,
          menuItemIdx
        )}}},[(menuItem.link)?_c('router-link',{staticClass:"label-wrapper",attrs:{"to":menuItem.link}},[(menuItem.icon)?_c('font-awesome-icon',{attrs:{"icon":menuItem.icon}}):_vm._e(),_c('div',{staticClass:"label"},[_vm._v(_vm._s(menuItem.label))])],1):(menuItem.url)?_c('a',{staticClass:"label-wrapper",attrs:{"href":menuItem.url}},[(menuItem.image)?_c('img',{attrs:{"src":menuItem.image}}):_vm._e(),_c('div',{staticClass:"label"},[_vm._v(_vm._s(menuItem.title))])]):_c('router-link',{staticClass:"label-wrapper",attrs:{"to":menuItem.link ? menuItem.link : menuItem.list[0].link}},[(menuItem.icon)?_c('font-awesome-icon',{attrs:{"icon":menuItem.icon}}):_vm._e(),_c('div',{staticClass:"label"},[_vm._v(_vm._s(menuItem.label))])],1),(menuItem.dropdown && menuItem.dropdown.status)?_c('FormulateInput',{staticClass:"dropdown",attrs:{"type":"select","value":menuItem.dropdown.value,"options":menuItem.dropdown.list},on:{"input":menuItem.dropdown.action}}):_vm._e()],1),(menuItem.list && menuItem.list.length)?_c('SubLinksList',{class:['subitem', { visibile: _vm.selectedMenuItem === menuItem.label }],attrs:{"menu-list":menuItem.list,"parent-item":Object.assign({}, menuItem, {index: menuItemIdx})}}):_vm._e(),(menuItem.list && menuItem.list.length)?_c('SubLinksList',{staticClass:"subitem__on-hover",attrs:{"menu-list":menuItem.list,"parent-item":Object.assign({}, menuItem, {index: menuItemIdx})}}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }
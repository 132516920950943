<template>
  <div class="sub-links">
    <div
      :class="[
        'item d-flex align-items-center',
        { active: checkIfActive(subItem) },
      ]"
      @click="
        action(subItem.label, subItemIdx, parentItem.index, parentItem.label)
      "
      v-for="(subItem, subItemIdx) in menuList"
      :key="subItemIdx"
    >
      <router-link :to="subItem.link" class="label">
        {{ subItem.label }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubLinksList",
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
    action: {
      type: Function,
      default: () => {},
    },
    parentItem: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    checkIfActive(subItem) {
      if (subItem.relation === this.$route.meta.relation) {
        if (["users"].includes(subItem.relation)) {
          if (subItem.link.params.kind === this.$route.params.kind) return true;
          else return false;
        } else return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.sub-links {
  font-family: "Montserrat", sans-serif;
  background: $background_3;
  overflow: hidden;

  .item {
    color: #292929;
    font-weight: 500;
    font-size: 0.65rem;
    margin-left: 25px;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover,
    &.active {
      color: $purple;
    }

    .label {
      color: inherit;
      text-decoration: none;
    }

    @media (min-width: 1600px) {
      font-size: 0.75rem;
    }
  }

  &.subitem {
    padding-top: 0;
    padding-bottom: 0;
    .item {
      height: 0;
      opacity: 0;
    }

    &.visibile {
      padding-top: 5px;
      padding-bottom: 5px;

      .item {
        height: 25px;
        line-height: 25px;
        opacity: 1;
      }

      @media (min-width: 1200px) {
        padding-top: 8px;
        padding-bottom: 8px;
        .item {
          height: 25px;
          line-height: 25px;
        }
      }

      @media (min-width: 1600px) {
        padding-top: 10px;
        padding-bottom: 10px;
        .item {
          height: 30px;
          line-height: 30px;
        }
      }
    }
  }

  &.subitem__on-hover {
    padding: 15px 15px 15px 0;

    .item {
      line-height: 35px;
    }
  }
}

.module-editor {
  .sub-links {
    &.subitem {
      &.visibile {
        @media (min-width: 1200px) {
          padding-top: 8px;
          padding-bottom: 8px;
        }

        @media (min-width: 1600px) {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="sidebar-menu" :style="style" :class="sidebarmenuClasses">
    <div class="sidebar">
      <font-awesome-icon
        icon="custom-times"
        class="d-md-none d-block close-icon"
        @click="toggleMenu(false)"
      />
      <div class="sidebar__top">
        <router-link to="/">
          <img
            :src="require('@/assets/logo-mobile-header.svg')"
            alt="Logo"
            class="sidebar__top__logo"
          />
          <img
            v-if="!menuOpened"
            :src="require('@/assets/re-logo.svg')"
            alt="Logo"
            class="sidebar__top__logo__small"
          />
        </router-link>
      </div>

      <LinksList :menu-list="menuList" :menu-opened="menuOpened" />

      <div class="sidebar__bottom">
        <Login
          v-if="loginVisibility"
          style="overflow: hidden"
          login-btn-width="100%"
        />

        <LinksList class="d-md-none d-block" :menu-list="mainMenuList" />

        <ProfileAndLogout
          v-if="logoutVisibility"
          style="overflow: hidden"
          :link-label="labels.profile_settings"
          :link="{ name: profilePath }"
          :labels="labels"
        />
      </div>

      <div class="sidebar__toggle d-md-block d-xl-none d-none">
        <div class="d-flex align-items-center" @click="toggleMenu(!menuOpened)">
          <font-awesome-icon :icon="toggleIcon" class="toggle-icon" />

          <span v-if="menuOpened">
            {{ labels.hide_menu }}
          </span>
        </div>
      </div>
    </div>
    <BottomMenu
      class="d-md-none d-flex"
      :menu-list="menuList"
      :action="toggleMenu"
    />
    <MessagesPopupWindow
      v-if="showMessages"
      :labels="labels"
      :messages="messages"
      @closePopup="showMessages = !showMessages"
    />

    <button
      v-if="!showMessages && showMessagesButton"
      class="messages-button"
      @click="showMessages = !showMessages"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-chat-dots"
        viewBox="0 0 16 16"
      >
        <path
          d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
        />
        <path
          d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import Login from "@/components/Common/Login";
import LinksList from "@/components/Menu/LinksList";
import ProfileAndLogout from "@/components/Common/ProfileAndLogout";
import BottomMenu from "@/components/Menu/BottomMenu";
import MessagesPopupWindow from "@/components/Popups/MessagesPopupWindow";

import { mapGetters, mapState, mapMutations, mapActions } from "vuex";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  customHome,
  customTimes,
  customNoteList,
  customLayout,
  customBarChart,
  customImage,
  customBookmark,
  customHandPackages,
  customMenu,
  customMonitor,
  customUser,
  customCog,
} from "@/assets/icons";

library.add(
  faChevronLeft,
  faChevronRight,
  customHome,
  customTimes,
  customNoteList,
  customLayout,
  customBarChart,
  customImage,
  customBookmark,
  customHandPackages,
  customMenu,
  customMonitor,
  customUser,
  customCog
);

export default {
  name: "SideBarMenu",
  components: {
    Login,
    LinksList,
    ProfileAndLogout,
    BottomMenu,
    MessagesPopupWindow,
  },
  data() {
    return {
      stateDropdownValue: "",
      serverMenu: [],
      mainMenuList: [],
      messages: [],
      showMessages: false,
      showMessagesButton: false,
    };
  },
  computed: {
    ...mapState("helper", {
      menuOpened: "menuOpened",
      dashboardMenu: "dashboardMenu",
      contracts: "contracts",
      monetizationBundle: "monetizationBundle",
    }),
    ...mapGetters("helper", ["labels"]),
    ...mapState("user", ["userIsAuthenticated", "userRole"]),
    ...mapState("monetizationBundle", {
      states: "states",
    }),
    isContracts() {
      return this.contracts.length > 0;
    },
    isAuthenticated() {
      return this.$store.getters["user/get"].isAuthenticated;
    },
    loginVisibility() {
      if (this.$store.getters["user/get"].isAuthenticated) return false;
      if (["login", "register"].includes(this.$route.name)) return false;
      return true;
    },
    logoutVisibility() {
      if (!this.$store.getters["user/get"].isAuthenticated) return false;
      return true;
    },
    style() {
      let output = {
        "--overflow-y": "auto",
      };
      if (this.$route.fullPath.includes("dashboard"))
        output["--overflow-y"] = "visible";
      return output;
    },
    stateDropdownStatus() {
      let output = false;
      if (this.$route.params.state) output = true;
      return output;
    },
    clientMenuList() {
      // let stateValue = '';
      // if ( this.$route.params.state ) {
      // 	this.states.forEach( item => {
      // 		if ( item.label === this.$route.params.state ) {
      // 			stateValue = item.value;
      // 		}
      // 	} );
      // }

      let menu = [
        {
          label: this.labels.home,
          link: "/",
          icon: "custom-home",
          relation: ["home"],
        },
        ...this.serverMenu,
        {
          label: this.labels.bookmarks,
          link: { name: "bookmarks" },
          icon: "custom-bookmark",
          relation: ["bookmarks"],
        },
        {
          label: this.labels.profile,
          link: { name: "account-profile" },
          icon: "custom-user",
          relation: ["profile"],
        },
      ];

      if (this.userRole == "admin") {
        menu.push({
          label: "Dashboard",
          link: { name: "dashboard" },
          icon: "custom-bar-chart",
        });
      }

      return menu;
    },
    menuList() {
      let output = this.clientMenuList;
      if (this.$route.fullPath.includes("dashboard"))
        output = this.dashboardMenuList;
      return output;
    },
    toggleIcon() {
      let output = "chevron-left";
      if (!this.menuOpened) output = "chevron-right";
      return output;
    },
    sidebarmenuClasses() {
      const editorRoutes = [
        "module-editor",
        "create-sub-module",
        "create-card",
        "create-definition",
        "module-editor-history",
        "module-editor-request",
        "module-editor-search",
        "module-editor-add-note",
      ];

      let result = {
        "menu-opened": this.menuOpened,
      };

      if (editorRoutes.includes(this.$route.name))
        result["module-editor"] = true;

      return result;
    },
    dashboardMenuList() {
      let result = [];

      for (let i = 0; i < this.dashboardMenu.length; i++) {
        let link = { ...this.dashboardMenu[i] };

        link.label = this.labels[link.label];

        if (link.list && link.list.length) {
          for (let x = 0; x < link.list.length; x++) {
            let sublink = link.list[x];

            if (this.labels[sublink.label]) {
              link.list[x].label = this.labels[sublink.label];
            }
          }
        }

        result.push(link);
      }

      return result;
    },
    profilePath() {
      if (this.$router.history.current.path.includes("dashboard")) {
        return "dashboard-profile";
      }
      return "account-profile";
    },
  },

  methods: {
    ...mapMutations("helper", {
      toggleMenu: "toggleMenu",
    }),
    ...mapActions("helper", {
      getBasic: "getBasic",
    }),
    stateDropdownChanged(value) {
      this.stateDropdownValue = value;
    },
    goToContractsSign() {
      if (this.userRole == "account") {
        if (this.$route.name !== "account-contracts-sign") {
          this.$router.push({ name: "account-contracts-sign" });
        }
      } else {
        if (this.$route.name !== "contracts-sign") {
          this.$router.push({ name: "contracts-sign" });
        }
      }
    },
  },
  mounted() {
    if (this.userIsAuthenticated) {
      this.getBasic()
        .then((res) => {
          if (res.success && res.messages && res.messages.length) {
            this.messages = res.messages;
            this.showMessages = true;
          }
          if (res.success && this.isContracts) this.goToContractsSign();
          if (res.menu?.body) this.serverMenu = Object.values(res.menu.body);
          if (this.$route.name == "account") {
            this.$router.push({
              name: "bundle",
              params: {
                monetization_bundle_id: res.monetizationBundle,
              },
            });
          }
          if (this.$route.path.includes("account")) {
            this.showMessagesButton = true;
          }
        })
        .catch((err) => console.log(err));
    }
  },
  watch: {
    userIsAuthenticated(newVal) {
      if (newVal) this.getBasic();
    },
    isContracts() {
      this.getBasic()
        .then(() => {
          this.goToContractsSign();
        })
        .catch((err) => console.log(err));
    },
    "$route.path"() {
      if (this.contracts.length > 0) this.$router.go(0);

      if (this.$route.name == "account") {
        this.$router.push({
          name: "bundle",
          params: {
            monetization_bundle_id: this.monetizationBundle,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.sidebar-menu {
  position: sticky;
  top: 0;
  z-index: 1031;
  max-height: 100vh;
  height: 100vh;
  flex: 0 0 0;
  transition: all 0.3s ease;

  @media (min-width: 768px) {
    flex: 0 0 50px;
  }

  @media (min-width: 1200px) {
    flex: 0 0 310px;
  }

  @media (min-width: 1600px) {
    flex: 0 0 340px;
  }

  &.menu-opened {
    @media (min-width: 1200px) {
      flex: 0 0 310px;
    }

    .sidebar {
      right: 0;

      @media (max-width: 1199px) {
        width: 310px;
        overflow-y: auto;
      }

      .login {
        @media (min-width: 768px) and (max-width: 1200px) {
          opacity: 1;
        }
      }
      .profile-card {
        @media (min-width: 768px) and (max-width: 1200px) {
          opacity: 1;
        }
      }

      ::v-deep > .menu > .menu__item {
        @media (min-width: 768px) and (max-width: 1200px) {
          > div > .label-wrapper > .label {
            display: block;
          }

          > .subitem {
            display: block;
          }
        }
      }
    }
  }

  &:not(.menu-opened) {
    .sidebar {
      ::v-deep > .menu > .menu__item {
        @media (min-width: 768px) and (max-width: 1200px) {
          &:not(:last-child) {
            margin-bottom: 25px;
          }

          > div > .label-wrapper > .label {
            display: none;
          }

          > .subitem {
            display: none;
          }
        }
      }

      &__top {
        &__search {
          @media (min-width: 768px) and (max-width: 1200px) {
            display: none;
          }
        }
      }
    }
  }

  .sidebar {
    position: fixed;
    top: 0;
    width: 50px;
    height: 100%;
    padding: 15px;
    background-color: #fcfcfc;
    white-space: nowrap;
    z-index: 1041;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 15px -6px #0000002e;
    overflow-y: var(--overflow-y);
    overflow-x: visible;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: $scrollbar_width;
    }

    &::-webkit-scrollbar-track {
      background: $light_gray;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $navy;
    }

    // @media (max-width: 767px) {
    // 	overflow-y: auto;
    // }

    &__top {
      overflow: hidden;

      &__logo {
        display: none;
        margin: 0 0 15px;
        max-width: 100%;
        height: 50px;
        width: auto;

        &__small {
          display: none;
          width: 20px;
          margin-bottom: 15px;
        }
      }

      &__search {
        margin: 0 0 15px;
      }
    }

    &__toggle {
      margin-top: auto;
      padding-top: 15px;

      span {
        margin-left: 15px;
        color: #b0b0b0;
      }
    }

    @media (min-width: 768px) {
      &__top {
        &__logo {
          display: block;
        }
      }
    }

    @media (min-width: 1200px) {
      position: absolute;
      width: 100%;

      &__top {
        &__logo {
          margin: 0 0 25px;
        }

        &__search {
          margin: 0 0 30px;
        }
      }
    }

    .login,
    .profile-card {
      @media (min-width: 768px) and (max-width: 1200px) {
        // display: none;
        opacity: 0;
        transition: all 0.3s ease;
      }
    }

    @media (max-width: 450px) {
      width: 100% !important;
    }

    @media (max-width: 767px) {
      right: -100%;
    }

    @media (min-width: 768px) {
      left: 0;
    }

    @media (min-width: 1200px) {
      // width: 310px;
      padding: 30px;
    }

    @media (min-width: 1600px) {
      // width: 340px;
      padding: 50px;
    }

    svg {
      display: block;
      color: #b0b0b0;
      cursor: pointer;

      &.close-icon {
        margin: -10px -10px 5px auto;
        font-size: 3rem;
      }
    }

    .profile-card {
      margin-top: 15px;

      @media (min-width: 1200px) {
        margin-top: 30px;
      }
    }

    @media (max-width: 1199.98px) {
      &__top {
        &__logo {
          display: none;

          &__small {
            display: block;
          }
        }
      }
    }
  }

  &.menu-opened {
    .sidebar {
      &__top {
        &__logo {
          display: block;

          &__small {
            display: none;
          }
        }
      }
    }
  }
}

.messages-button {
  position: fixed;
  z-index: 9999999;
  right: 20px;
  bottom: 30px;
  @media (max-width: 767px) {
    bottom: 100px;
  }
  width: 50px;
  height: 50px;
  background-color: $white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: 0.3s all ease-in-out;
  &:hover {
    opacity: 0.7;
  }
  svg {
    width: 30px;
    height: 30px;
    fill: $navy;
  }
}
.sidebar-menu.module-editor {
  @media (min-width: 1200px) {
    flex: 0 0 50px;
  }

  @media (min-width: 1500px) {
    flex: 0 0 310px;
  }

  @media (min-width: 1600px) {
    flex: 0 0 340px;
  }

  &.menu-opened {
    @media (min-width: 1200px) {
      flex: 0 0 50px;
    }

    @media (min-width: 1600px) {
      flex: 0 0 340px;
    }

    .sidebar {
      @media (max-width: 1499px) {
        width: 310px;
        overflow-y: auto;
      }

      .login {
        @media (min-width: 768px) and (max-width: 1500px) {
          opacity: 1;
        }
      }
      .profile-card {
        @media (min-width: 768px) and (max-width: 1500px) {
          opacity: 1;
        }
      }

      ::v-deep > .menu > .menu__item {
        @media (min-width: 768px) and (max-width: 1500px) {
          > div > .label-wrapper > .label {
            display: block;
          }

          > .subitem {
            display: block;
          }
        }
      }
    }
  }

  &:not(.menu-opened) {
    .sidebar {
      @media (max-width: 1199.98px) {
        &__top {
          &__logo {
            display: block;

            &__small {
              display: none;
            }
          }
        }
      }

      @media (max-width: 1499.98px) {
        &__top {
          &__logo {
            display: none;

            &__small {
              display: block;
            }
          }
        }
      }

      ::v-deep > .menu > .menu__item {
        @media (min-width: 768px) and (max-width: 1500px) {
          &:not(:last-child) {
            margin-bottom: 25px;
          }

          > div > .label-wrapper > .label {
            display: none;
          }

          > .subitem {
            display: none;
          }
        }
      }

      &__top {
        &__search {
          @media (min-width: 768px) and (max-width: 1500px) {
            display: none;
          }
        }
      }
    }
  }
  .sidebar {
    @media (min-width: 1200px) {
      padding: 15px;
    }

    @media (min-width: 1500px) {
      padding: 30px;
    }

    @media (min-width: 1600px) {
      // padding: 30px;
      padding: 50px;
    }

    .login,
    .profile-card {
      @media (min-width: 768px) and (max-width: 1500px) {
        // display: none;
        opacity: 0;
        transition: all 0.3s ease;
      }
    }

    .profile-card {
      margin-top: 15px;

      @media (min-width: 1200px) {
        margin-top: 15px;
      }

      @media (min-width: 1500px) {
        margin-top: 30px;
      }
    }

    &__toggle {
      @media (min-width: 768px) and (max-width: 1500px) {
        display: block !important;
      }
    }

    @media (min-width: 1200px) {
      position: absolute;
      width: 100%;

      &__top {
        &__logo {
          margin: 0 0 15px;
        }

        &__search {
          margin: 0 0 15px;
        }
      }
    }

    @media (min-width: 1500px) {
      position: absolute;
      width: 100%;

      &__top {
        &__logo {
          margin: 0 0 25px;
        }

        &__search {
          margin: 0 0 30px;
        }
      }
    }
  }
}
</style>

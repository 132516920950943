<template>
  <div class="background-plane">
    <div class="wrapper">
      <div class="header">
        <div class="popup-title">{{ labels.messages }}</div>
        <button @click="closePopup">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </button>
      </div>
      <div class="body">
        <div class="message-list">
          <div v-for="(message, index) in messages" :key="index">
            <div v-if="!message.archived" class="message-item">
              <div class="message-item-content">
                <div class="message-item-content-title">
                  {{ message.title }}
                </div>
                <div class="message-item-content-text">{{ message.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <Button
          :label="labels.close"
          variant="btn-outline-purple"
          data-dismiss="modal"
          style="fontweight: 600"
          @click="closePopup"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button";

export default {
  name: "MessagesPopupWindow",
  components: {
    Button,
  },
  props: {
    labels: {
      type: Object,
      default: () => {},
    },
    messages: {
      type: Array,
      default: () => {},
    },
  },
  methods: {
    closePopup() {
      this.$emit("closePopup");
    },
  },
};
</script>

<style lang="scss" scoped>
.background-plane {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba($color: #000000, $alpha: 0.5);
}
.wrapper {
  max-width: 650px;
  width: 100%;
  height: fit-content;
  position: relative;
  top: 100px;
  right: 0;
  background-color: #fff;
  z-index: 999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0 10px 1px rgba($color: #000000, $alpha: 0.3);
  overflow: hidden;
  margin: 0 25px;
  .header {
    background-color: #f2f2f2;
    padding: 20px 25px;
    font-size: 1.125rem;
    font-weight: 700;
    font-family: "Oswald", sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      border: none;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .body {
    padding: 10px;
    overflow-y: scroll;
    max-height: 300px;
    &::-webkit-scrollbar {
      display: none;
    }

    .message-list {
      .message-item {
        padding: 15px;
        border-radius: 0.3rem;
        box-shadow: 0 0 10px 1px rgba($color: #000000, $alpha: 0.1);
        margin-bottom: 15px;
        .message-item-content-title {
          font-weight: 700;
          margin-bottom: 20px;
        }
      }
    }
  }
  .footer {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding: 25px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}
</style>
